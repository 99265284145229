<template>
  <div class="px-5">
    <v-card
      class="px-5 py-3 mb-5"
    >
      <v-row>
        <v-col
          cols="2"
        >
          <v-list rounded>
            <v-subheader>选择数据表</v-subheader>
            <v-list-item-group
              v-model="item"
              color="primary"
            >
              <template
                v-for="form in forms"
              >
                <v-list-item
                  v-if="!form.children"
                  :key="form.id"
                  @click="upReport(form.id)"
                >
                  <v-list-item-icon>
                    <v-icon>fa fa-pencil-square-o</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ form.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-group
                  v-if="form.children"
                  :key="form.id"
                  prepend-icon="fa fa-pencil-square-o"
                  append-icon="fa fa-angle-down"
                  @click="upReport(form.id)"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ form.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="children in form.children"
                    :key="children.id"
                    @click="upReport(children.id)"
                  >
                    <v-list-item-title>{{ children.name }}</v-list-item-title>
                    <v-list-item-action>
                      <v-icon>fa fa-pencil</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-group>
              </template>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col
          cols="10"
        >
          <div class="my-5">
            <v-btn
              color="success"
              @click="createReportBtn"
            >
              <v-icon left>
                fa fa-plus
              </v-icon>
              新增填报
            </v-btn>
            <v-btn
              color="success"
              @click="batchImportDialog = true"
            >
              <v-icon left>
                fa fa-cloud-upload
              </v-icon>
              导入
            </v-btn>
            <v-btn
              color="success"
              @click="batchUploadDialog = true"
            >
              <v-icon left>
                fa fa-refresh
              </v-icon>
              批量更新
            </v-btn>
            <v-btn
              color="success"
              @click="createReportBtn"
            >
              <v-icon left>
                fa fa-undo
              </v-icon>
              批量重置
            </v-btn>
          </div>
          <v-simple-table>
            <thead>
              <tr>
                <th width="50">
                  #
                </th>
                <th>名称</th>
                <th width="180">
                  用户
                </th>
                <th width="180">
                  创建时间
                </th>
                <th width="50">
                  审核状态
                </th>
                <th
                  width="280"
                  class="text-right"
                >
                  操作
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(report, index) in reportList"
                :key="report.id + index"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ report.name }}</td>
                <td>{{ report.user.nickname }}</td>
                <td>{{ report.createdAt | dateformat }}</td>
                <td>
                  <v-chip
                    v-if="report.status === 10"
                    class="ma-2"
                    color="success"
                  >
                    通过
                  </v-chip>
                  <v-chip
                    v-if="report.status === 5"
                    class="ma-2"
                    color="info"
                  >
                    正在审核
                  </v-chip>
                  <v-chip
                    v-if="report.status === 0"
                    class="ma-2"
                    color="warning"
                  >
                    暂存
                  </v-chip>
                  <v-chip
                    v-if="report.status === -5"
                    class="ma-2"
                    color="error"
                  >
                    驳回
                  </v-chip>
                </td>
                <td class="text-right">
                  <v-btn
                    class="mx-2"
                    small
                    color="info"
                    @click="authorizeReportBtn(index)"
                  >
                    <v-icon
                      small
                      left
                    >
                      fa fa-unlock-alt
                    </v-icon>
                    权限
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    small
                    color="success"
                    @click="editReportBtn(index)"
                  >
                    <v-icon
                      small
                      left
                    >
                      mdi-pencil
                    </v-icon>
                    编辑
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    small
                    color="error"
                    @click="delReport(index)"
                  >
                    <v-icon
                      small
                      left
                    >
                      fa fa-trash-o
                    </v-icon>
                    删除
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="6"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="editReportDialog"
      class="dialog_box"
      fullscreen
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>填报内容</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="editReportDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <div class="form_box">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <form-create
                    v-model="formCreateRule.fApi"
                    :rule="formCreateRule.rule"
                    :option="formCreateRule.option"
                    :value.sync="formValue"
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="saveBtnShow"
            color="success"
            @click="saveBtn"
          >
            新增
          </v-btn>
          <v-btn
            v-if="!saveBtnShow"
            color="success"
            @click="editBtn"
          >
            修改
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="authorizeReportDialog"
      max-width="800px"
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>设置角色与用户组</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="authorizeReportDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <div class="form_box">
            <v-container>
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-treeview
                    v-model="roles"
                    selectable
                    shaped
                    hoverable
                    open-all
                    selection-type="independent"
                    :items="roleTree"
                    @input="selectRole"
                  />
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-treeview
                    v-model="teams"
                    selectable
                    shaped
                    hoverable
                    open-all
                    selection-type="independent"
                    :items="teamTree"
                    @input="selectTeam"
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="setRole"
          >
            设置
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="batchImportDialog"
      fullscreen
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>批量导入</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="batchImportDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <batchImport
          :form-id="formId"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="batchUploadDialog"
      fullscreen
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>批量更新</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="batchUploadDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <batchUpload
          :form-id="formId"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Upload from '../../../src/views/report/component/Upload.vue'
  import http from '../../api'
  import tree from '../../utils/tree'
  import { ruleFormat, dataFormat } from '../../utils/form'
  import batchImport from './component/batchImport.vue'
  import batchUpload from './component/batchUpload.vue'
  export default {
    components: {
      batchImport: batchImport,
      batchUpload: batchUpload,
    },
    data () {
      return {
        roles: [],
        teams: [],
        roleTree: [],
        teamTree: [],
        item: 0,
        page: 1,
        actions: [
          {
            color: 'success',
            icon: 'mdi-pencil',
          },
          {
            color: 'error',
            icon: 'mdi-close',
          },
        ],
        dialog: false,
        forms: '',
        formId: '',
        reportList: [],
        editReportDialog: false,
        saveBtnShow: true,
        authorizeReportDialog: false,
        batchImportDialog: false,
        batchUploadDialog: false,
        reportId: '',
        formCreateRule: {
          // 实例对象
          fApi: {},
          // 表单生成规则
          rule: [],
          // 组件参数配置
          option: {
            submitBtn: false,
            form: {
              labelWidth: undefined,
              labelPosition: 'top',
            },
            row: {
              gutter: 30,
            },
          },
        },
        formValue: {},
      }
    },
    mounted () {
      Vue.component('uploadFiles', Upload)
    },
    created () {
      this.getForms()
    },
    methods: {
      getForms () {
        http.form.getForms()
          .then(res => {
            const resData = res.data.data
            const treeData = tree(resData, {
              id: 'id',
              pid: 'parentId',
              children: 'children',
            })
            this.forms = treeData
            this.getReport(this.forms[0].id)
            this.formId = this.forms[0].id
          })
      },
      upReport (id) {
        this.getReport(id)
        this.formId = id
      },
      getReport (id) {
        http.report.queryReportList({
          formId: id,
        })
          .then(res => {
            this.reportList = res.data.data.content
          })
      },
      // 创建填报
      async createReportBtn () {
        await http.form.getFormFields(this.formId)
          .then(async res => {
            this.formCreateRule.rule = await ruleFormat(res.data.data)
          })
        this.saveBtnShow = true
        this.editReportDialog = true
      },
      // 保存按钮
      saveBtn () {
        this.formCreateRule.fApi.validate((valid) => {
          if (valid) {
            this.createReport()
          } else {
            this.$swal({
              title: '表单验证未通过',
              text: '请查看红色表单项与提示',
              icon: 'warning',
              confirmButtonText: '知道了',
            })
          }
        })
      },
      // 创建填报方法
      createReport () {
        const formValue = JSON.parse(JSON.stringify(this.formValue))
        for (const key in formValue) {
          if (Array.isArray(formValue[key])) {
            console.log(formValue[key])
            formValue[key] = this.formValue[key].toString()
            console.log(formValue[key])
          }
        }
        http.report.saveReport(this.formId, {
          data: formValue,
        })
          .then((res) => {
            this.editReportDialog = false
            this.$swal({
              title: '新增成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch((err) => {
            console.log(err)
            this.$swal({
              title: '新增失败!',
              icon: 'error',
            })
          })
      },
      // 编辑填报
      async editReportBtn (index) {
        this.saveBtnShow = false
        this.editReportDialog = true
        this.reportId = this.reportList[index].id
        await http.form.getFormFields(this.reportList[index].formId)
          .then(async res => {
            this.formCreateRule.rule = await ruleFormat(res.data.data)
          })
        http.report.getReportContent(this.reportList[index].id)
          .then(async res => {
            const resData = res.data.data.data
            this.formValue = dataFormat(resData, this.formCreateRule.rule)
          })
      },
      // 弹框底部编辑按钮
      editBtn () {
        this.formCreateRule.fApi.validate((valid) => {
          if (valid) {
            this.editReport(this.reportId)
          } else {
            this.$swal({
              title: '表单验证未通过',
              text: '请查看红色表单项与提示',
              icon: 'warning',
              confirmButtonText: '知道了',
            })
          }
        })
      },
      // 编辑填报
      editReport (reportId) {
        const formValue = JSON.parse(JSON.stringify(this.formValue))
        for (const key in formValue) {
          if (Array.isArray(formValue[key])) {
            console.log(formValue[key])
            formValue[key] = this.formValue[key].toString()
            console.log(formValue[key])
          }
        }
        http.report.uploadReport(reportId, {
          data: formValue,
        })
          .then((res) => {
            this.editReportDialog = false
            this.$swal({
              title: '修改成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch((err) => {
            console.log(err)
            this.$swal({
              title: '修改失败!',
              icon: 'error',
            })
          })
      },
      // 填报授权
      authorizeReportBtn (index) {
        this.getRoles()
        this.getTeams()
        this.authorizeReportDialog = true
        this.reportId = this.reportList[index].id
        http.access.getAccess('report', this.reportId)
          .then(res => {
            this.roles = res.data.data
            this.teams = res.data.data
          })
      },
      authorizeReport (index) {},
      // 删除填报
      delReport (index) {
        this.$swal({
          title: '你确定要删除"' + this.reportList[index].name + '"吗？',
          text: '删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            http.report.deleteReport(this.reportList[index].id)
              .then(res => {
                this.reportList.splice(index, 1)
                this.$swal({
                  title: '删除成功',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
              .catch(err => {
                this.$swal({
                  title: '删除失败',
                  icon: 'error',
                  text: '原因:' + err.response.data.msg,
                  confirmButtonText: '知道了',
                })
              })
          }
        })
      },
      async getRoles () {
        http.dictionaries.getDicRoles2()
          .then(res => {
            const roles = res.data.data
            this.roleTree = tree(roles, {
              id: 'id',
              pid: 'parentId',
              children: 'children',
            })
          })
          .catch(err => {
            console.log(err)
          })
      },
      async getTeams () {
        http.dictionaries.getDicTeams()
          .then(res => {
            const teams = res.data.data
            this.teamTree = tree(teams, {
              id: 'id',
              pid: 'parentId',
              children: 'children',
            })
          })
          .catch(err => {
            console.log(err)
          })
      },
      selectRole (val) {
        this.roles = val
      },
      selectTeam (val) {
        this.teams = val
      },
      setRole () {
        http.access.putAccess('report', this.reportId, 'access', [...this.roles, ...this.teams])
          .then(res => {
            this.authorizeReportDialog = false
            this.$swal({
              title: '设置成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            this.$swal({
              title: '设置失败',
              icon: 'error',
              text: '原因:' + err.response.data.msg,
              confirmButtonText: '知道了',
            })
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main{
    margin-top: 0;
  }
</style>
