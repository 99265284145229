<template>
  <div>
    <v-card-text class="pt-4">
      <div class="form_box">
        <v-row>
          <v-col
            cols="4"
          >
            <v-file-input
              v-model="excelFile"
              label="上传表格"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="1"
          >
            <v-btn
              color="success"
              @click="uploadExcel"
            >
              <v-icon left>
                fa fa-upload
              </v-icon>
              上传
            </v-btn>
          </v-col>
          <v-col
            cols="2"
          >
            <v-btn
              color="info"
              @click="downTemplate"
            >
              <v-icon left>
                fa fa-download
              </v-icon>
              下载导入模板
            </v-btn>
          </v-col>
          <v-col
            cols="1"
          >
            <v-btn
              :disabled="importDataBtn"
              color="success"
              @click="importExcel"
            >
              <v-icon left>
                fa fa-upload
              </v-icon>
              导入数据
            </v-btn>
          </v-col>
          <v-col
            cols="12"
          >
            <v-alert
              v-if="excelFileData !== []"
              class="pa-0"
              dense
              type="info"
            >
              请先上传数据，上传成功后即可导入数据。
            </v-alert>
            <v-simple-table
              v-if="excelFileData !== []"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="name in excelFileHeader"
                      :key="name.id"
                      class="text-left"
                    >
                      {{ name.name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(fileData, index) in excelFileData"
                    :key="index"
                  >
                    <td
                      v-for="(fd, i) in fileData"
                      :key="'c' + i"
                    >
                      {{ fd }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        :disabled="importDataBtn"
        color="success"
        @click="importExcel"
      >
        导入数据
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
  import http from '../../../api'
  import file from '../../../utils/file'
  export default {
    props: {
      formId: {
        type: String,
        default: '15',
      },
    },
    data () {
      return {
        excelFile: null,
        excelFileData: [],
        excelFileHeader: [],
        importDataBtn: true,
      }
    },
    methods: {
      importExcel () {
        http.form.importForm(this.formId, this.excelFile)
          .then(res => {
            this.batchImportDialog = false
            this.$swal({
              title: '导入成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            this.$swal({
              title: '导入失败',
              icon: 'error',
              text: '原因:' + err.response.data.msg,
              confirmButtonText: '知道了',
            })
          })
      },
      downTemplate () {
        http.form.exportTemplates(this.formId)
          .then(res => {
            console.log(res.data)
            file.downFile(res.data, '导入模板')
          })
      },
      uploadExcel () {
        http.form.getFormFields(this.formId)
          .then(res => {
            this.excelFileHeader = res.data.data
          })
        http.file.uploadExcel(this.excelFile)
          .then(res => {
            this.excelFileData = res.data.data
            this.importDataBtn = false
          })
      },
    },
  }
</script>

<style>

</style>
